@font-face {
	font-family: "Optima";
	src: url("assets/fonts/Linotype\ -\ OptimaLTPro-Roman.otf");
}

@font-face {
	font-family: "Futura LT Pro";
	src: url("assets/fonts/Linotype\ -\ FuturaLTPro-Medium.otf");
	font-weight: 500;
}

@font-face {
	font-family: "Futura LT Pro-light";
	src: url("assets/fonts/Linotype\ -\ FuturaLTPro-Light.otf");
	font-weight: 300;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: linear-gradient(270deg, #ffe8e2 10.67%, #ffc5b2 100%);

}

.MuiPaper-elevation {
	max-height: 300px !important;
}

.MuiPaper-elevation li {
	font-family: 'Futura LT Pro-light';
}

button.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
	color: #0000004d;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
	color: #000;
}

button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.Mui-selected {
	background-color: #000;
	color: #fff;
}

.MuiAutocomplete-popper div {
	overflow-y: hidden !important;
	max-height: 400px !important;
	margin-bottom: 25px;
	font-family: 'Futura LT Pro-light';
}

.MuiAutocomplete-popper li {
	font-family: 'Futura LT Pro-light';
}
.MuiPaper-root .MuiCalendarPicker-root {
	max-height: 300px
}

input, textarea {
	-webkit-appearance: none;
    -moz-appearance: none;
	-o-appearance: none;
    appearance: none;
	background-clip: padding-box;
	background: transparent !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
